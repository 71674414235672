.slack__popup__main {
  width: 100%;
  min-height: 100vh;
  position: absolute;
  top: 0;
  left: 0;

  .popup__section {
    width: 100%;
    min-height: calc(100vh - 96px);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 20px 20px;

    .popup__container {
      width: 100%;
      max-width: 560px;

      .__popup {
        width: 100%;
        border: 1px solid #6ddcff;
        background: #1e2020;
        padding: 33px 22px;

        .heading_logo_container {
          display: flex;
          gap: 15px;
          align-items: center;

          .analytics_logo {
            width: 30px;
            height: 30px;
            flex-shrink: 0;
          }

          .heading {
            color: #fff;
            font-family: var(--Inter);
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin: 0;
          }
        }

        .line {
          width: 100%;
          height: 2px;
          flex-shrink: 0;
          stroke-width: 1px;
          background-color: #3d3d3d;
          margin-top: 15px;
        }
      }

      .continue__btn__container {
        display: flex;
        justify-content: center;
        margin-top: 46px;
      }
    }
  }
  .label{
    color: white;
    font-size: 18px;
    margin-bottom: 8px;
    margin-left: 2px;
  }
  .search_input {
    width: 100%;
    padding: 17px 22px;
    align-items: center;
    gap: 13px;
    flex-shrink: 0;
    border-radius: 5px;
    border: 1px solid #27272a;
    background: #000;
    color: #fff;
    font-family: vcar(--IBMPlexSans);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  .search_input:focus {
    outline: none;
  }
  .search_input::placeholder {
    color: #fff;
    font-family: vcar(--IBMPlexSans);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  .notes_para {
    margin-top: 14px;
    color: #fff;
    font-family: var(--Inter);
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
  }

}
