.contact_container_main {
  background: var(--black);
  width: 100%;
  padding: 0px 92px;
  .contact_container {
    flex-shrink: 0;
    margin: auto;
    text-align: center;
    background: url("../../assets/svg/connect-bg.svg") no-repeat;
    background-position: bottom;
    background-position-y: 200px;
    transition: background-position 2s linear;
    .contact_title_container {
      margin-top: 169px;
      .title {
        color: var(--white);
        text-align: center;
        font-family: var(--IBMPlexSans);
        font-size: 60px;
        font-style: normal;
        font-weight: 400;
        line-height: 66px;
      }
    }
    .email_container {
      margin-bottom: 150px;
      .email_icon {
        width: 49px;
        height: 49px;
        flex-shrink: 0;
        margin-bottom: 20px;
      }
      .email_title {
        color: var(--white);
        font-family: var(--IBMPlexSans);
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 34px;
        text-align: center;
      }
      .email {
        color: #a1a1aa;
        text-align: center;
        font-family: var(--Inter);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px; /* 162.5% */
        text-decoration-line: underline;
      }
    }
    .footer_container {
      .footer_bg {
        height: 135px;
        display: flex;

        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;
      }
      .logo_container {
        .logo {
          // width: 37px;
          height: 37px;
          flex-shrink: 0;
        }
      }
      .copy_right_container {
        width: 426px;
        .content {
          color: #a1a1aa;
          font-family: var(--IBMPlexSans);
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 30px;
          text-align: left;
        }
      }
      .quick_links_container {
        .quick_links {
          list-style: none;
          display: flex;
          justify-content: space-between;
          gap: 30px;
          .quick_links_item {
            color: #fafafa;
            text-align: right;
            font-family: var(--IBMPlexSans);
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            border-bottom: 2px solid #52525b;
            padding-bottom: 12px;
            cursor: pointer;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .contact_container_main {
    padding: 30px 20px 60px 20px;
    .contact_container {
      background: none;
      .contact_title_container {
        margin-top: 60px;
        .title {
          font-size: 43px;
        }
      }
      .email_container {
        margin-bottom: 100px;
      }
      .footer_container {
        .footer_bg {
          height: 135px;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
        }
        .logo_container {
          margin-bottom: 20px;
          .logo {
            // width: 37px;
            height: 37px;
            flex-shrink: 0;
          }
        }
        .copy_right_container {
          width: 100%;
          .content {
            color: #a1a1aa;
            font-family: var(--IBMPlexSans);
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 30px;
            text-align: left;
          }
        }
        .quick_links_container {
          .quick_links {
            list-style: none;
            display: flex;
            justify-content: space-between;
            gap: 30px;
            .quick_links_item {
              color: #fafafa;
              text-align: right;
              font-family: var(--IBMPlexSans);
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              border-bottom: 2px solid #52525b;
              padding-bottom: 12px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}


@media only screen and (max-width: 988px) {
  .contact_container_main {
    padding: 60px 20px;
  }
}

@media only screen and (max-width: 285px) {
  .contact_container_main {
    padding: 105px 20px;
  }
  .quick_links_item {
    font-size: 11px !important;
  }
  .quick_links {
    margin-left: -15px;
  }
}
