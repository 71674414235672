.select_container {
  margin-bottom: 10px;
  position: relative;
  .select_input_container {
    display: flex;
    padding: 15px 22px;
    align-items: center;
    gap: 13px;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 5px;
    border: 1px solid #27272a;
    background: #000;
    justify-content: space-between;
    cursor: pointer;
  }
  .select_input_placeholder {
    color: #fff;
    font-family: var(--IBMPlexSans);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin: 0;
    background: transparent;
    border: none;
    width: 100%;
  }
  .select_input_placeholder:focus{
    outline: none;
  }
  .select_icon {
    width: 11.667px;
    height: 5.833px;
    flex-shrink: 0;
    background-image: url("../../../assets/svg/select-icon.svg");
    background-position: center;
    background-repeat: no-repeat;
  }
  .select_items_container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    border: 1px solid #27272a;
    background: #000;
    padding: 20px;
    // position: absolute;
    width: 100%;
    .select_check_box {
      width: 26px;
      height: 26px;
      border-radius: 4px;
      border: 1px solid #d9d9d9;
      cursor: pointer;
    }
    .selected_check_box {
      width: 26px;
      height: 26px;
      border-radius: 4px;
      border: 1px solid #000;
      background: #7f60f9;
      cursor: pointer;
    }
    .select_meta_data_container {
      .select_item_name {
        color: #fff;
        font-family: var(--Inter);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 17px;
        margin: 0;
      }
      .select_item_id {
        color: #fff;
        font-family: var(--Inter);
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        margin: 0;
      }
    }
  }
  .dropdown__list{
    position: absolute;
    width: 100%;
    max-height: 310px;
    overflow: auto;
  }
}