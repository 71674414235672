.admin_popup_container {
  width: 560px;
  height: auto;
  flex-shrink: 0;
  border: 1px solid #6ddcff;
  background: #1e2020;
  padding: 20px 30px 40px 30px;

  // margin-top: 120px;
  .heading_container {
    .heading {
      color: #fff;
      font-family: var(--Inter);
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .sub_title {
      color: #fff;
      font-family: var(--Inter);
      font-size: 11px;
      font-weight: 400;
      line-height: normal;
      margin-top: 5px;
    }

    .line {
      width: 490px;
      height: 2px;
      flex-shrink: 0;
      stroke-width: 1px;
      background-color: #3d3d3d;
      margin-top: 16px;
    }
  }

  .user__list__container {
    display: flex;
    flex-direction: column;
    gap: 17px;
    margin-top: 17px;

    .user_container {
      .user_name {
        color: #FFF;
        font-family: var(--Inter);
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
      }

      .user__join__date {
        color: #FFF;
        font-family: var(--IBMPlexSans);
        font-size: 9px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 5px;
      }
    }

    .imporsonate__btn {
      border: none;
      background-color: transparent;
      padding: 0;
      color: #7F60F9;
      font-family: var(--IBMPlexSans);
      font-size: 14px;
      font-weight: 600;
      text-decoration-line: underline;
    }
  }
}

@media only screen and (max-width: 768px) {
  .dashboard_popup_container {
    width: 90%;
    height: auto;
    margin-left: 0;
    padding: 20px 10px;

    .heading_container {
      .heading {
        text-align: center;
      }

      .line {
        width: 100%;
      }
    }
  }
}

@media only screen and (max-width: 285px) {
  .dashboard_popup_container {
    width: 86%;
    height: auto;
    margin-left: 0;

    .heading_container {
      .line {
        width: 100%;
      }
    }

  }
}