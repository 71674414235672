.connect_popup_container {
  width: 560px;
  height: auto;
  flex-shrink: 0;
  border: 1px solid #6ddcff;
  background: #1e2020;
  padding: 20px 30px 40px 30px;
  .heading_container {
    .heading {
      color: #fff;
      font-family: var(--Inter);
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    .line {
      width: 490px;
      height: 2px;
      flex-shrink: 0;
      stroke-width: 1px;
      background-color: #3d3d3d;
      margin-top: 15px;
    }
  }
  .item_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin: 25px 0px;
    .item_metadata_container {
      display: flex;
      justify-content: "flex-start";
      gap: 15px;
      align-items: flex-start;
      .item_icon_container {
        .item_icon {
          width: 30px;
          height: 30px;
          flex-shrink: 0;
        }
      }
      .item_metadata_name_container {
        .item_name {
          color: #fff;
          font-family: var(--IBMPlexSans);
          font-size: 17px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-bottom: 6px;
        }
        .connected_container {
          margin-top: -2px;
          display: flex;
          gap: 5px;
          align-items: center;
          .red_circle {
            width: 10px;
            height: 10px;
            flex-shrink: 0;
            background-color: #e01e5a;
            border-radius: 90px;
          }
          .green_circle {
            width: 10px;
            height: 10px;
            flex-shrink: 0;
            background-color: #2eb67d;
            border-radius: 90px;
          }
          .connect_text {
            color: #fff;
            font-family: var(--IBMPlexSans);
            font-size: 11px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
      }
      .connect_btn_container {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .item_line {
    width: 490px;
    height: 2px;
    flex-shrink: 0;
    stroke-width: 1px;
    background-color: #3d3d3d;
    margin-top: 13px;
  }
  .properties_connected {
    color: #fff;
    font-family: var(--IBMPlexSans);
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: -10px;
  }
}

@media only screen and (max-width: 768px) {
  .connect_popup_container {
    width: 90%;
    height: auto;
    margin-left: 20px;
    padding: 20px 10px;
    .heading_container {
      .heading {
        text-align: center;
      }
      .line {
        width: 100%;
      }
    }
    .item_metadata_name_container {
      .item_name {
        font-size: 13px !important;
      }
    }

    .item_line {
      width: 100%;
    }
  }
}
@media only screen and (max-width: 285px) {
  .connect_popup_container {
    width: 86%;
    height: auto;
    margin-left: 20px;
    .heading_container {
      .line {
        width: 100%;
      }
    }
    .item_metadata_container {
      gap: 6px !important;
    }
    .item_line {
      width: 100%;
    }
  }
}
