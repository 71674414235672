.analytic_popup_container {
  width: 560px;
  height: auto;
  flex-shrink: 0;
  border: 1px solid #6ddcff;
  background: #1e2020;
  padding: 30px 30px 56px 30px;
  // position: relative;
  // margin: 50px 0px;
 
  .heading_container {
    .heading_logo_container {
      display: flex;
      gap: 15px;
      align-items: center;
      .analytics_logo {
        width: 30px;
        height: 30px;
        flex-shrink: 0;
      }
      .heading {
        color: #fff;
        font-family: var(--Inter);
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin: 0;
      }
    }
  }
  .account_head_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    .head_name {
      color: #fff;
      font-family: var(--IBMPlexSans);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
    .add_account {
      color: #fff;
      font-family: var(--IBMPlexSans);
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 150% */
      text-decoration-line: underline;
    }
  }
  .account_email_container {
    margin-top: 12px;
    .account_email {
      color: #fff;
      font-family: var(--IBMPlexSans);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 14px;
    }
  }
  .apps_select_container {
    margin-top: 22px;
    .apps_select_heading {
      color: #fff;
      font-family: var(--IBMPlexSans);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
  }
  .properties_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    .properties_name_container {
      margin-top: 16px;
      .properties_name {
        color: #fff;
        font-family: var(--Inter);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 2px;
      }
      .properties_id {
        color: #fff;
        font-family: var(--Inter);
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
      }
    }
    .remove_btn {
      color: #e01e5a;
      text-align: right;
      font-family: var(--IBMPlexSans);
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
  .line {
    width: 490px;
    height: 2px;
    flex-shrink: 0;
    stroke-width: 1px;
    background-color: #3d3d3d;
    margin-top: 15px;
  }
}
.continue_close_btn_container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
  .close_btn {
    color: #fff;
    text-align: center;
    font-family: var(--Inter);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
    margin-top: 15px;
    cursor: pointer;
  }
}

@media only screen and (max-width: 768px) {
  .analytic_popup_container {
    width: 95%;
    height: auto;
    padding: 20px 10px 40px;
    .heading_container {
      .heading {
        text-align: left;
      }
    }
    .line {
      width: 100%;
    }
  }
}
@media only screen and (max-width: 285px) {
  .analytic_popup_container {
    width: 93%;
    margin: 0px 10px;
    height: auto;
    .heading_container {
      .line {
        width: 100%;
      }
    }
    .add_account {
      font-size: 13px !important;
    }
    .properties_name {
      line-height: 18px !important;
    }
  }
}
