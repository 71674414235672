.popup__top__bar {
    background-color: #090914;
    height: 96px;
    display: flex;
    align-items: center;
    padding: 0px 59px;
    gap: 32px;

    button {
        padding: 0;
        border: none;
        outline: none;
        background-color: transparent;
    }

    .title__text {
        color: #FFF;
        font-family: var(--IBMPlexSans);
        font-size: 16px;
        font-weight: 500;
        line-height: normal;
    }

    &.pos__absolute{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
    }
}