.aha__moment__logo {
    display: flex;
    align-items: center;
    gap: 12px;

    div {
        color: #2ABFB7;
        font-family: var(--Inter);
        font-size: 19px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
    }
}