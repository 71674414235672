    .remove_btn {
    color: #e01e5a;
    text-align: right;
    font-family: var(--IBMPlexSans);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    outline: none;
    border: none;
    background-color: transparent;
    padding: 0;
}