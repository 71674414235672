@font-face {
    font-family: 'Inter';
    src: url('Inter-Medium.ttf');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url('Inter-Light.ttf');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url('Inter-Regular.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url('Inter-Bold.ttf');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url('Inter-ExtraBold.ttf');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url('Inter-ExtraLight.ttf');
    font-weight: 200;
    font-style: normal;
}
 
