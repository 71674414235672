.steps_container {
  width: 100%;
  height: 680px;
  flex-shrink: 0;
  background: var(--black);
  align-items: center;
  padding-top: 50px;
  .title_container {
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
  }
  .steps_title {
    color: var(--white);
    text-align: center;
    font-family: var(--IBMPlexSans);
    font-size: 60px;
    font-style: normal;
    font-weight: 400;
    line-height: 66px;
    width: 619px;
  }
  .disclaimer_container {
    color: var(--grey);
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
  }
  .steps_lists_container {
    display: flex;
    justify-content: center;
    margin-top: 28px;
    .steps_list {
      list-style-type: none;
      counter-reset: num;
    }

    .steps_list .steps_item {
      counter-increment: num;
      position: relative;
      padding-left: 55px;
    }

    .steps_list .steps_item::before {
      content: counter(num);
      color: var(--white);
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      color: var(--white);
      font-family: var(--IBMPlexSans);
      font-size: 17px;
      font-style: normal;
      font-weight: 600;
      line-height: 36px;
      display: flex;
      justify-content: center;
    }

    .steps_item {
      color: var(--white);
      font-family: var(--IBMPlexSans);
      font-size: 21px;
      font-style: normal;
      font-weight: 400;
      line-height: 32px;
      width: 485px;
      margin-bottom: 20px;
    }

    .steps_item::before {
      content: "";
      width: 38px;
      height: 38px;
      flex-shrink: 0;
      background-image: url("../../assets/svg/steps-point.svg");
      background-size: cover;
      background-repeat: no-repeat;
      margin-right: 10px;
    }

    .steps_highlight {
      color: var(--white);
      font-family: var(--IBMPlexSans);
      font-size: 21px;
      font-style: normal;
      font-weight: 400;
      line-height: 32px;
      text-decoration-line: underline;
    }
  }
  .cta_btn_container {
    display: flex;
    justify-content: center;
    margin: 15px 0 25px;
    .cta_container {
      display: flex;
      width: 245px;
      height: 50px;
      padding: 16px;
      justify-content: center;
      align-items: center;
      border-radius: 60px;
      border: 1px solid #6ddcff;
      gap: 10px;
      flex-shrink: 0;
      background: transparent;
      .cta_img {
        width: 24.95px;
        height: 24.93px;
        flex-shrink: 0;
      }
      .cta_text {
        color: var(--white);
        text-align: right;
        font-family: var(--Inter);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        margin: 0;
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .steps_container {
    .title_container {
      align-items: center;
      display: flex;
      justify-content: center;
    }
    .steps_title {
      font-size: 40px;
      line-height: 51px;
    }
    .steps_lists_container {
      display: flex;
      justify-content: center;
      margin-top: 28px;
      .steps_list {
        padding: 0px 20px;
      }

      .steps_item {
        width: 100%;
      }
    }
  }
}

@media only screen and (max-width: 285px) {
  .steps_container {
    .steps_title {
      font-size: 29px;
      line-height: 39px;
    }
    .steps_lists_container {
      .steps_item {
        font-size: 17px;
      }
      .steps_highlight {
        font-size: 17px;
      }
    }
  }
}
