.bullet__btn {
    width: 26px;
    height: 26px;
    border-radius: 100%;
    border: 1px solid #FFF;
    background-color: transparent;

    &.active {
        background-color: #7F60F9;
        border: 1px solid #7F60F9;
    }
}