.navbar_container {
  width: 100%;
  height: 96px;
  flex-shrink: 0;
  background-color: var(--dark-blue);
  display: flex;
  justify-content: space-between;
  padding: 0px 80px;
  align-items: center;
  z-index: 9;

  .menu_icon {
    display: none;
  }

  .logo_menu_container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .logo_container {
    margin-left: 30px;
  }

  .logo_img {
    // width: 134px;
    margin: 0 5px 0 0;
    height: 37px;
    flex-shrink: 0;
    cursor: pointer;
  }
  .nav__tab__container{
    display: flex;
    align-items: center;
    gap: 39px;
    margin-left: 35px;
  }
  .admin__nav__tab {
    color: #FFF;
    font-family: var(--Inter);
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    border: none;
    background-color: transparent;
    border-bottom: 1px solid transparent;
    padding-bottom: 7px;

    &.active__tab {
      border-color: #6DDCFF;
      color: #6DDCFF;
    }
  }

  .menu_item_container {
    margin-left: -5px;
  }

  .menu_container {
    list-style-type: none;
    display: flex;
    gap: 20px;
    align-items: center;
    margin: 0;
  }

  .menu_item {
    color: var(--white);
    font-family: var(--IBMPlexSans);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    cursor: pointer;
  }

  .cta_container {
    display: flex;
    width: 220px;
    height: 50px;
    padding: 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 60px;
    border: 1px solid #6ddcff;
    background: transparent;
  }

  .cta_img {
    width: 24.95px;
    height: 24.93px;
    flex-shrink: 0;
  }

  .cta_text {
    color: var(--white);
    text-align: right;
    font-family: var(--Inter);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    margin: 0;
  }

  .logout__btn {
    color: #a1a1aa;
    font-family: var(--Inter);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin: 0;
    padding: 0px;
    background-color: transparent;
    border: none;
    outline: none;
  }
}

@media only screen and (max-width: 768px) {
  .navbar_container {
    padding: 0px 20px;

    .menu_icon {
      display: block;
      width: 30px;
      height: 30px;
    }

    .logo_container {
      margin-left: 0px;
    }

    .btn_container {
      display: flex;
      gap: 20px;
      justify-content: center;
      align-items: center;
    }

    .menu_item_container {
      position: absolute;
      height: 149px;
      background-color: var(--dark-blue);
      display: flex;
      margin: 0px 9px;
      top: 107px;
      right: 0;
      left: 0px;
      border-radius: 10px;
      padding-top: 17px;
      border: 1px solid #6ddcff;
    }

    .menu_container {
      list-style-type: none;
      display: block;
      margin: 0;
      gap: 20px;
    }

    .menu_item {
      font-size: 22px;
      margin-bottom: 20px;
    }

    .cta_container {
      width: 165px;
    }
  }

  .drawer_show {
    opacity: 1;
  }

  .drawer_hide {
    opacity: 0;
  }

  .default_drawer {
    display: none !important;
  }
}

@media only screen and (max-width:525px) {
  .navbar_container {
    height: 86px;

    .cta_container {
      &.hid__on__mob {
        display: none;
      }

      &.mob__signin__btn {
        padding: 0px;
        border: none;
        width: fit-content;
        display: block !important;

        p {
          color: #FFF;
          font-family: var(--Inter);
          font-size: 16px;
          font-weight: 700;
          line-height: 24px;
        }
      }
    }
  }
}

@media only screen and (max-width: 285px) {
  .navbar_container {
    padding: 0px 20px;

    .cta_container {
      display: none;
    }

    .menu_icon {
      display: block;
      width: 30px;
      height: 30px;
    }

    .logo_container {
      margin-left: 0px;
    }

    .btn_container {
      display: flex;
      gap: 20px;
      justify-content: center;
      align-items: center;
    }

    .menu_item_container {
      position: absolute;
      height: 149px;
      background-color: var(--dark-blue);
      display: flex;
      margin: 0px 9px;
      top: 107px;
      right: 0;
      left: 0px;
      border-radius: 10px;
      padding-top: 17px;
      border: 1px solid #6ddcff;
    }

    .menu_container {
      list-style-type: none;
      display: block;
      margin: 0;
      gap: 20px;
    }

    .menu_item {
      font-size: 22px;
      margin-bottom: 20px;
    }

    .cta_container {
      width: 165px;
    }
  }

  .drawer_show {
    opacity: 1;
  }

  .drawer_hide {
    opacity: 0;
  }

  .default_drawer {
    display: none !important;
  }
}