@import "bootstrap/dist/css/bootstrap.min.css";
@import "antd/dist/reset.css";

:root {
  --dark-blue: #090914;
  --white: #ffffff;
  --black: #000000;
  --grey: #a1a1aa;
  --IBMPlexSans: "IBMPlexSans";
  --Inter: "Inter";
}

* {
  margin: 0;
  padding: 0;
}
body{
  background: var(--black);
}

a {
  text-decoration: none;
}
.cursor__pointer{
  cursor: pointer;
}
.error{
  color: rgb(170, 37, 37);
  font-size: 13px;
  font-weight: 500;
}
