.connect_btn {
  display: flex;
  width: 123px;
  height: 28px;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 60px;
  border: 1px solid #6ddcff;
  background-color: transparent;
  color: #fff;
  text-align: right;
  font-family: var(--Inter);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
}
.connect_btn_property {
  display: flex;
  width: auto;
  height: 28px;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 60px;
  border: 1px solid #6ddcff;
  background-color: transparent;
  color: #fff;
  font-family: var(--Inter);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
}

@media only screen and (max-width: 768px) {
  .connect_btn {
    width: 330px;
    font-size: 15px;
    margin-top: 10px;
  }
  .connect_btn_property {
    width: 330px;
    font-size: 15px;
    margin-top: 10px;
  }
}

@media only screen and (max-width:525px){
  .properties_remove_container,.connect_btn_container {
    width: 100%;
  }
  .connect_btn_property {
    font-size: 12px;
    width: 100%;
  }
}

@media only screen and (max-width: 285px) {
  .connect_btn {
    font-size: 12px;
    width: 215px;
  }
  .properties_remove_container,.connect_btn_container {
    width: 100%;
  }
  .connect_btn_property {
    font-size: 12px;
    width: 100%;
  }
}
