.main_container {
  width: 100%;
  min-height: 100vh;
  background: var(--black);
  display: flex;
  justify-content: center;
  .content_container {
    background: url("../../assets/svg/popup-light.svg") no-repeat;
    background-position: center;
    background-size: 58%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    .btn_container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 30px;
    }
    .content {
      color: var(--white);
      text-align: center;
      font-family: var(--Inter);
      font-size: 14px;
      font-style: italic;
      font-weight: 600;
      line-height: normal;
      margin-top: 25px;
    }
  }

  &.is_impersonate {
    min-height: calc(100vh - 80px) ;

    .content_container {
      min-height: calc(100vh - 80px);
    }
  }
}