// .popup_slack_container {
//   width: 560px;
//   height: auto;
//   flex-shrink: 0;
//   border: 1px solid #6ddcff;
//   background: #1e2020;
//   padding: 30px 30px 0px 30px;
//   // margin-top: 80px;
//   .heading_container {
//     .heading_logo_container {
//       display: flex;
//       gap: 15px;
//       align-items: center;
//       .analytics_logo {
//         width: 30px;
//         height: 30px;
//         flex-shrink: 0;
//       }
//       .heading {
//         color: #fff;
//         font-family: var(--Inter);
//         font-size: 16px;
//         font-style: normal;
//         font-weight: 600;
//         line-height: normal;
//         margin: 0;
//       }
//     }
//   }
//   .account_head_container {
//     margin-top: 20px;
//     .head_slack_connect {
//       color: #fff;
//       font-family: var(--Inter);
//       font-size: 16px;
//       font-style: normal;
//       font-weight: 600;
//       line-height: 18px;
//       margin-top: 10px;
//     }
//   }
//   .color_line {
//     width: 252px;
//     height: 2px;
//     background: linear-gradient(90deg, #77deff 34.13%, #7f60f9 62.9%);
//     margin: 12px 0px 30px 0px;
//   }
//   .line {
//     width: 490px;
//     height: 2px;
//     flex-shrink: 0;
//     stroke-width: 1px;
//     background-color: #3d3d3d;
//     margin-top: 15px;
//   }
//   .bottom_line {
//     width: 490px;
//     height: 2px;
//     flex-shrink: 0;
//     stroke-width: 1px;
//     background-color: #3d3d3d;
//     margin: 30px 0px;
//   }

//   .channels_select_container {
//     margin-top: 22px;
//     .apps_select_heading {
//       color: #fff;
//       font-family: var(--IBMPlexSans);
//       font-size: 16px;
//       font-style: normal;
//       font-weight: 400;
//       line-height: 24px;
//     }
//     .head_slack_connect {
//       color: #fff;
//       font-family: var(--Inter);
//       font-size: 16px;
//       font-style: normal;
//       font-weight: 600;
//       text-decoration: underline;
//     }
//   }
//   .properties_container {
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     .properties_name_container {
//       margin-top: 16px;
//       .properties_name {
//         color: #fff;
//         font-family: var(--Inter);
//         font-size: 14px;
//         font-style: normal;
//         font-weight: 400;
//         line-height: 2px;
//       }
//       .properties_id {
//         color: #fff;
//         font-family: var(--Inter);
//         font-size: 10px;
//         font-style: normal;
//         font-weight: 400;
//       }
//     }
//     .remove_btn {
//       color: #e01e5a;
//       text-align: right;
//       font-family: var(--IBMPlexSans);
//       font-size: 14px;
//       font-style: normal;
//       font-weight: 600;
//       line-height: normal;
//     }
//   }
//   .continue_close_btn_container {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     margin-top: 25px;
//     .close_btn {
//       color: #fff;
//       text-align: center;
//       font-family: var(--Inter);
//       font-size: 16px;
//       font-style: normal;
//       font-weight: 600;
//       line-height: 26px;
//       margin-top: 15px;
//       cursor: pointer;
//     }
//   }
// }

// @media only screen and (max-width: 768px) {
//   .popup_slack_container {
//     min-width: 345px;
//     width: 95%;
//     height: auto;
//     padding: 20px 10px;
//     .heading_container {
//       .heading {
//         text-align: left;
//       }
//     }
//     .line {
//       width: 100%;
//     }
//     .bottom_line {
//       width: 100%;
//     }
//   }
// }
// @media only screen and (max-width: 285px) {
//   .popup_slack_container {
//     min-width: 240px;
//     margin-left: 4px;
//     width: 90%;
//     height: auto;
//     .heading_container {
//       .line {
//         width: 100%;
//       }
//     }
//     .add_account {
//       font-size: 13px !important;
//     }
//     .properties_name {
//       line-height: 18px !important;
//     }
//   }
// }

.slack__popup__main {
  width: 100%;
  min-height: 100vh;
  position: absolute;
  top: 0;
  left: 0;

  .popup__section {
    width: 100%;
    min-height: calc(100vh - 96px);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 20px 20px;

    .popup__container {
      width: 100%;
      max-width: 560px;

      .__popup {
        width: 100%;
        border: 1px solid #6ddcff;
        background: #1e2020;
        padding: 33px 22px;

        .heading_logo_container {
          display: flex;
          gap: 15px;
          align-items: center;

          .analytics_logo {
            width: 30px;
            height: 30px;
            flex-shrink: 0;
          }

          .heading {
            color: #fff;
            font-family: var(--Inter);
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin: 0;
          }
        }

        .line {
          width: 490px;
          height: 2px;
          flex-shrink: 0;
          stroke-width: 1px;
          background-color: #3d3d3d;
          margin-top: 15px;
        }
        .channel__list__item {
          display: flex;
          align-items: center;
          gap: 18px;
          padding: 10px 0px;

          .channel__name {
            color: #fff;
            font-family: var(--Inter);
            font-size: 14px;
            font-weight: 400;
            line-height: 17px;
          }
        }
      }

      .continue__btn__container {
        display: flex;
        justify-content: center;
        margin-top: 46px;
      }
    }
  }
  .search_container {
    display: flex;
    width: 100%;
    margin-top: 20px;
    height: 60px;
  }
  .search_input {
    width: 100%;
    padding: 17px 22px;
    align-items: center;
    gap: 13px;
    flex-shrink: 0;
    border-radius: 5px;
    border: 1px solid #27272a;
    background: #000;
    color: #fff;
    font-family: vcar(--IBMPlexSans);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  .search_input:focus {
    outline: none;
  }
  .search_input::placeholder {
    color: #fff;
    font-family: vcar(--IBMPlexSans);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  .notes_para {
    margin-top: 14px;
    color: #fff;
    font-family: var(--Inter);
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
    .high_lighted {
      color: #fff;
      font-family: var(--Inter);
      font-size: 14px;
      font-style: italic;
      font-weight: 400;
      line-height: normal;
      text-decoration-line: underline;
    }
  }

  .add_slack_button {
    margin: 10px 0px;
    display: flex;
    background-color: transparent;
    width: 169px;
    height: 28px;
    padding: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 60px;
    border: 1px solid #6ddcff;
    color: #fff;
    text-align: right;
    font-family: var(--Inter);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 185.714% */
  }
  .scroll_container{
    height: 400px !important;
    overflow: auto !important;
  }
}
