.main_container {
  .connect_main_container {
    width: 100%;
    min-height: 100vh !important;
    background: var(--black);
    align-items: center;

    .content_container {
      background: url("../../assets/svg/popup-light.svg") no-repeat;
      background-position: center;
      background-size: 58%;
      min-height: 100vh !important;
      display: flex;
      justify-content: center;
      width: 100%;
      align-items: center;
      position: absolute;
      left: 0;
      top: 0;
      padding-top: 80px;

      .btn_container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 30px;
      }

      .content {
        color: var(--white);
        text-align: center;
        font-family: var(--Inter);
        font-size: 14px;
        font-style: italic;
        font-weight: 600;
        line-height: normal;
        margin-top: 25px;
      }
    }

    .main_popup_container {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 100vh !important;
      padding: 40px;
    }
  }

  &.is_impersonate {
    .connect_main_container {
      min-height: calc(100vh - 80px) !important;
      .content_container,.main_popup_container{
        min-height: calc(100vh - 80px) !important;

      }
    }
  }
}