.continue_btn {
  display: flex;
  width: 245px;
  height: 50px;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 60px;
  border: 1px solid #6ddcff;
  background-color: transparent;
  color: #fff;
  text-align: right;
  font-family: var(--Inter);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px; /* 162.5% */
}

@media only screen and (max-width: 285px) {
  .continue_btn {
    width: 180px;
  }
}
