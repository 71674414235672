.privacy_policy_container {
  background: #000;
  padding-top: 140px;
  padding-bottom: 80px;
  width: 70%;
  margin: auto;
  .main_heading {
    color: #fff;
    text-align: center;
    font-family: var(--IBMPlexSans);
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 66px;
  }
  .content_container {
    margin-top: 30px;
    .content_heading {
      color: #fff;
      font-family: var(--IBMPlexSans);
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    .content {
      color: #fff;
      font-family: var(--IBMPlexSans);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-top: 20px;
    }
  }
  a {
    color: var(--white);
    border-bottom: 1px solid var(--white);
  }
}

@media only screen and (max-width: 925px) {
  .privacy_policy_container {
    width: 90%;
    .main_heading {
        font-size: 30px;
      }
  }
}
