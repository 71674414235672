.hero_container {
  width: 100%;
  height: 680px;
  flex-shrink: 0;
  background: var(--black);
  padding: 0px 0px 10px 92px;
  display: flex;
  justify-content: space-between;
  background-image: url("../../assets/svg/light.svg");
  background-position: right;
  background-size: 60%;
  background-repeat: no-repeat;
  padding-top: 140px;
  .left_container {
    width: 43%;
    max-width: 756px;
    padding-top: 125px;
    .hero_heading {
      font-family: var(--IBMPlexSans);
      font-size: 69px;
      font-style: normal;
      font-weight: 400;
      line-height: 85px;
      letter-spacing: -2px;
      background: linear-gradient(98deg, #6ddcff 0%, #7f60f9 100%);
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      margin: 0px;
      .heading_highlight {
        background: var(--white);
        -webkit-background-clip: text;
        font-family: var(--IBMPlexSans);
        font-size: 69px;
        font-style: normal;
        font-weight: 400;
        line-height: 85px;
        letter-spacing: -2px;
      }
    }
    .hero_sub_content {
      color: var(--grey);
      font-family: var(--Inter);
      font-size: 21px;
      font-style: normal;
      font-weight: 400;
      line-height: 34px;
      margin: 10px 0px 15px 0px;
    }
    .cta_btn_container {
      margin-left: 30px;
    }
    .cta_container {
      display: flex;
      width: 245px;
      height: 50px;
      padding: 16px;
      justify-content: center;
      align-items: center;
      border-radius: 60px;
      border: 1px solid #6ddcff;
      gap: 10px;
      flex-shrink: 0;
      background: transparent;
    }
    .cta_img {
      width: 24.95px;
      height: 24.93px;
      flex-shrink: 0;
    }
    .cta_text {
      color: var(--white);
      text-align: right;
      font-family: var(--Inter);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
      margin: 0;
    }
    .line {
      width: 393px;
      height: 1.5px;
      background: #27272a;
      margin-top: 38px;
    }
    .bottom_section_container {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
      margin-top: 29px;
      .tranding_icon {
        width: 22px;
        height: 22px;
        flex-shrink: 0;
      }
      .bottom_section_content {
        color: var(--white);
        font-family: var(--IBMPlexSans);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        margin: 0;
      }
    }
  }
  .right_container {
    width: 50%;
    max-width: 756px;
    align-items: center;
    display: flex;
    justify-content: center;
    background-repeat: no-repeat;
    margin-right: 30px;
    .list_container {
      width: 390px;
      height: 530px;
      flex-shrink: 0;
      border: 1px solid #6ddcff;
      background: #1e2020;
      padding: 25px 25px 0px 25px;
      .title {
        color: var(--white);
        font-family: var(--Inter);
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      .list_line {
        width: 100%;
        height: 1.5px;
        flex-shrink: 0;
        stroke-width: 1px;
        background: #3d3d3d;
        margin-top: 18px;
      }
      .list_content {
        display: flex;
        justify-content: flex-start;
        margin: 18px 0;
        gap: 15px;
      }
      .list_img_user {
        width: 48px;
        height: 48px;
        flex-shrink: 0;
        border-radius: 4px;
      }
      .list_img_logo {
        width: 28px;
        height: 28px;
        flex-shrink: 0;
        border-radius: 4px;
      }
      .list_img_logo_background {
        background-color: #090914;
        height: fit-content;
        padding: 10px;
        border-radius: 4px;
      }
      .user_name {
        color: var(--white);
        font-family: var(--Inter);
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin: 0px;
        margin-bottom: 6px;
      }
      .user_caption {
        color: var(--white);
        font-family: var(--Inter);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 6px;
      }
      .user_hashtag {
        color: #00a3ff;
        font-family: var(--Inter);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
}
@media only screen and (max-width: 780px) {
  .hero_container {
    height: auto;
    flex-wrap: wrap;
    padding: 100px 16px 0px 16px;
    .left_container {
      width: 100%;
      padding: 41px 0px;
      .hero_heading {
        font-size: 49px;
        line-height: 67px;
        text-align: center;
        .heading_highlight {
          font-size: 49px;
          line-height: 67px;
          text-align: center;
        }
      }
      .hero_sub_content {
        font-size: 20px;
        margin: 10px 0px 15px 0px;
        text-align: center;
      }
      .cta_btn_container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 0px;
      }
      .line {
        width: 100%;
      }
      .bottom_section_container {
        .tranding_icon {
          width: 22px;
          height: 22px;
          flex-shrink: 0;
        }
        .bottom_section_content {
          text-align: center;
        }
      }
    }
    .right_container {
      width: 100%;
      background-image: url("../../assets/svg/light.svg");
      background-position: bottom;
      background-size: 100%;
      display: flex;
      justify-content: center;
      background-repeat: no-repeat;
      margin: auto;
      padding-bottom: 20px;
      align-items: center;
      .list_container {
        width: 100%;
      }
    }
  }
}
@media only screen and (max-width: 285px) {
  .hero_container {
    .left_container {
      width: 100%;
      padding: 41px 0px;
      .hero_heading {
        font-size: 40px;
        line-height: 50px;
        text-align: center;
        .heading_highlight {
          font-size: 40px;
          line-height: 50px;
          text-align: center;
        }
      }
      .hero_sub_content {
        font-size: 19px;
      }
    }
    .right_container {
      .list_container {
        height: 580px;
      }
    }
  }
}
