.main_container {
  width: 100%;
  .content_container_dashboard {
    background: var(--black);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 120px 0px;
    .btn_container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 30px;
    }
    .content {
      color: var(--white);
      text-align: center;
      font-family: var(--Inter);
      font-size: 14px;
      font-style: italic;
      font-weight: 600;
      line-height: normal;
      margin-top: 25px;
    }
  }
}
.main_dashboard_popup_container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
