.connect_container {
  width: 100%;
  flex-shrink: 0;
  background-color: var(--black);
  padding: 0px 92px;
  .content_bg {
    background: url("../../assets//svg/connect-bg.svg") no-repeat;
    background-position: center;
    background-size: cover;
  }
  .title_container {
    .title {
      color: var(--white);
      font-family: var(--IBMPlexSans);
      font-size: 45px;
      font-style: normal;
      font-weight: 400;
      line-height: 66px;
      margin: 0px;
    }
    .sub_title {
      color: #a1a1aa;
      font-family: var(--Inter);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 34px;
      margin: 0px;
      width: 570px;
      margin: auto;
    }
  }
  .provider_container {
    margin-top: 58px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    gap: 30px;
    .provider_card {
      display: flex;
      justify-content: flex-start;
      gap: 20px;
      .provider_image {
        width: 40px;
        height: 40px;
        flex-shrink: 0;
      }
      .provider_detail {
        .title_btn_container {
          display: flex;
          justify-content: flex-start;
          gap: 20px;
          .provider_name {
            color: var(--white);
            font-family: var(--IBMPlexSans);
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: 34px;
          }
          .coming_btn {
            display: flex;
            width: 123px;
            height: 28px;
            padding: 16px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 10px;
            flex-shrink: 0;
            border-radius: 60px;
            border: 1px solid #6ddcff;
            color: var(--white);
            font-family: var(--Inter);
            text-align: right;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 26px;
            background-color: transparent;
          }
        }
        .provider_content {
          color: #a1a1aa;
          font-family: var(--Inter);
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 26px;
          width: 296px;
        }
      }
    }
  }
}

@media only screen and (max-width: 925px) {
  .connect_container {
    height: auto;
    padding: 30px 20px 10px 20px;
    .content_bg {
      background-size: contain;
    }
    .title_container {
      .title {
        font-size: 40px;
        line-height: 45px;
      }
      .sub_title {
        color: #a1a1aa;
        font-family: var(--Inter);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 34px;
        margin: 10px 0px;
        width: 100%;
      }
    }
    .provider_container {
      margin-top: 58px;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      align-items: center;
      .provider_card_left {
        margin-left: 0px;
      }
      .provider_card {
        display: flex;
        justify-content: flex-start;
        gap: 10px;
        .provider_image {
          width: 40px;
          height: 40px;
          flex-shrink: 0;
        }
        .provider_detail {
          .provider_name {
            color: var(--white);
            font-family: var(--IBMPlexSans);
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: 34px;
          }
          .provider_content {
            color: #a1a1aa;
            font-family: var(--Inter);
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 26px;
            width: 296px;
          }
        }
      }
    }
  }
}


@media only screen and (max-width: 285px) {
  .connect_container {
    padding: 140px 20px 10px 20px;
    .title_container {
      .title {
        font-size: 30px;
        line-height: 40px;
      }
      .sub_title {
        font-size: 13px;
      }
    }
    .provider_container {
      margin-top: 50px;
      .provider_card {
        display: flex;
        justify-content: flex-start;
        gap: 10px;
        .coming_btn {
          width: 96px !important;
          font-size: 9px !important;
        }
        .provider_detail {
          .provider_name {
            font-size: 19px !important;
          }
          .provider_content {
            color: #a1a1aa;
            font-family: var(--Inter);
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 26px;
            width: 100%;
          }
        }
      }
    }
  }
}
