.dashboard_popup_container {
  width: 560px;
  height: auto;
  flex-shrink: 0;
  border: 1px solid #6ddcff;
  background: #1e2020;
  padding: 20px 30px 40px 30px;
  // margin-top: 120px;
  .heading_container {
    .heading {
      color: #fff;
      font-family: var(--Inter);
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    .sub_title {
      color: #fff;
      font-family: var(--Inter);
      font-size: 12px;
      font-style: italic;
      font-weight: 400;
      line-height: normal;
    }
    .line {
      width: 490px;
      height: 2px;
      flex-shrink: 0;
      stroke-width: 1px;
      background-color: #3d3d3d;
      margin-top: 15px;
    }
  }
  .item_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 10px;
    margin: 20px 0px;
    .item_metadata_container {
      display: flex;
      justify-content: "flex-start";
      gap: 15px;
      align-items: center;
      .item_icon_container {
        .item_icon {
          width: 30px;
          height: 30px;
          flex-shrink: 0;
        }
      }
      .item_metadata_name_container {
        .item_name {
          color: #fff;
          font-family: var(--IBMPlexSans);
          font-size: 17px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-bottom: -2px;
        }
        .connected_container {
          margin-top: 5px;
          display: flex;
          gap: 5px;
          align-items: center;
          .red_circle {
            width: 10px;
            height: 10px;
            flex-shrink: 0;
            background-color: #e01e5a;
            border-radius: 90px;
          }
          .green_circle {
            width: 10px;
            height: 10px;
            flex-shrink: 0;
            background-color: #2eb67d;
            border-radius: 90px;
          }
          .connect_text {
            color: #fff;
            font-family: var(--IBMPlexSans);
            font-size: 11px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
      }
      .connect_btn_container {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .item_line {
    width: 490px;
    height: 2px;
    flex-shrink: 0;
    stroke-width: 1px;
    background-color: #3d3d3d;
    margin-top: 13px;
  }
  .properties_connected {
    color: #fff;
    font-family: var(--IBMPlexSans);
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .heading_container {
    .heading_logo_container {
      display: flex;
      gap: 15px;
      align-items: center;
      .analytics_logo {
        width: 30px;
        height: 30px;
        flex-shrink: 0;
      }
      .heading {
        color: #fff;
        font-family: var(--Inter);
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin: 0;
      }
    }
  }
  .account_head_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    .head_name {
      color: #fff;
      font-family: var(--IBMPlexSans);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
    .add_account {
      color: #fff;
      font-family: var(--IBMPlexSans);
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      /* 150% */
      text-decoration-line: underline;
    }
  }
  .account_email_container {
    margin-top: 12px;
    .account_email {
      color: #fff;
      font-family: var(--IBMPlexSans);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 14px;
    }
  }
  .apps_select_container {
    margin-top: 22px;
    .apps_select_heading {
      color: #fff;
      font-family: var(--IBMPlexSans);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
  }
  .properties_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0px;
    flex-wrap: wrap;
    .properties_name_container {
      .properties_name {
        color: #fff;
        font-family: var(--Inter);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
      }
      .properties_id {
        color: #fff;
        font-family: var(--Inter);
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        max-width: 200px;
      }
    }
    .remove_btn {
      color: #e01e5a;
      text-align: right;
      font-family: var(--IBMPlexSans);
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
  .continue_close_btn_container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
    .close_btn {
      color: #fff;
      text-align: center;
      font-family: var(--Inter);
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 26px;
      margin-top: 15px;
      cursor: pointer;
    }
  }
  .line {
    width: 490px;
    height: 2px;
    flex-shrink: 0;
    stroke-width: 1px;
    background-color: #3d3d3d;
    margin-top: 15px;
  }
  .channel_name {
    color: #fff;
    font-family: var(--Inter);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }
}

@media only screen and (max-width: 768px) {
  .dashboard_popup_container {
    width: 90%;
    height: auto;
    margin-left: 0;
    padding: 20px 10px;
    .heading_container {
      .heading {
        text-align: center;
      }
      .line {
        width: 100%;
      }
    }
    .item_metadata_name_container {
      .item_name {
        font-size: 13px !important;
      }
    }

    .item_line {
      width: 100%;
    }
  }
}

@media only screen and (max-width:525px) {
  .properties_id {
    max-width: inherit !important;
  }
}

@media only screen and (max-width: 285px) {
  .dashboard_popup_container {
    width: 86%;
    height: auto;
    margin-left: 0;
    .heading_container {
      .line {
        width: 100%;
      }
    }
    .item_metadata_container {
      gap: 6px !important;
    }
    .item_line {
      width: 100%;
    }
  }
}
