.no_account_popup_container {
  width: 560px;
  height: auto;
  flex-shrink: 0;
  border: 1px solid #6ddcff;
  background: #1e2020;
  padding: 30px 30px 10px 30px;
  position: relative;
  // margin: 50px 0px;

  .heading_container {
    .heading_logo_container {
      display: flex;
      gap: 15px;
      align-items: center;
      .analytics_logo {
        width: 30px;
        height: 30px;
        flex-shrink: 0;
      }
      .heading {
        color: #fff;
        font-family: var(--Inter);
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin: 0;
      }
    }
  }

  .continue_close_btn_container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
    .close_btn {
      color: #fff;
      text-align: center;
      font-family: var(--Inter);
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 26px;
      margin-top: 15px;
      cursor: pointer;
    }
  }
  .line {
    width: 490px;
    height: 2px;
    flex-shrink: 0;
    stroke-width: 1px;
    background-color: #3d3d3d;
    margin-top: 15px;
  }
  .message {
    color: white;
    margin: 30px 0px;
    font-size: 16px;
    font-family: var(--Inter);
    text-align: center;
  }
  .img_container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    .no_account_img {
      width: 90px;
      height: 90px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .no_account_popup_container {
    width: 95%;
    height: auto;
    padding: 20px 10px;
    .heading_container {
      .heading {
        text-align: left;
      }
    }
    .line {
      width: 100%;
    }
  }
}
@media only screen and (max-width: 285px) {
  .no_account_popup_container {
    width: 93%;
    margin: 0px 10px;
    height: auto;
    .heading_container {
      .line {
        width: 100%;
      }
    }
    .add_account {
      font-size: 13px !important;
    }
    .properties_name {
      line-height: 18px !important;
    }
  }
}
