.main_container {
    width: 100%;
}

.impersonate__container {
    width: 100%;
    padding: 25px;
    background-color: #6DDCFF;
    display: flex;
    align-items: center;
    justify-content: center;

    button {
        text-decoration: underline;
        background-color: transparent;
        padding: 0;
        border: none;
        color: #000;
        font-family: var(--IBMPlexSans);
        font-size: 16px;
        font-weight: 600;
    }
}